import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// Us
import Icon from '@models/Icon';
import { CustomTheme } from '@components/layout/Theme';
import IconComponent from './Icon';

// A styled Box component that represents a rectangle with a border
const Rectangle = styled(Box)(({ theme }: { theme: CustomTheme }) => ({
  color: theme.palette.common.white,
  backgroundColor: '#528BBC',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '1.5rem',
  // clipPath: 'polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%)',
}));

interface OwnProps {
  text?: string;
  icon?: Icon;
  tooltip?: string;
}

export default function TriRectangle({ text = undefined, icon = undefined, tooltip = undefined }: OwnProps) {
  const compose = () => (
    <Box
      id="tri-rectangle"
      // Use sx prop to apply flexbox layout and responsive styles
      sx={{
        display: 'inline-block',
        whiteSpace: 'nowrap',
        height: '100%',
      }}
    >
      <Rectangle id="innerRect" sx={{ clipPath: 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)' }}>
        {icon && (<IconComponent icon={icon} />)}
        {text && (
          <Typography
            component="span"
            style={{
              marginLeft: '.3rem', fontSize: '14px', fontWeight: 'bold', cursor: 'default',
            }}
          >
            {text}
          </Typography>
        )}
      </Rectangle>
    </Box>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {compose()}
    </Tooltip>
  ) : compose();
}
